import { useState, useEffect, React } from "react"
import '../Styles/Categoria.css'
import '../Styles/Obituario.css'
import { api } from '../api.js'
import env from '../env.js'

const ENDPOINT = env === 'production' ? `https://obituario.lutocuritiba.com.br/` : ``

function Categoria(){
    const [user, setUser] = useState(null)
    const [search, setSearch] = useState("")
    const [contrato, setContrato] = useState([])

    useEffect(() => {
        api.get('/category-auth/user')
        .then(response => {
            setUser(response.data)
        })
        .catch(error => {
            console.error('Error fetching user data:', error)
        })
    }, [])

    function getCategory(){
        switchLoader(1)
        api.get('/pegasus/category', { params: { contrato: search } })
        .then(res => {
            const cto = res.data.cto
            if(cto !== null){
                setContrato([{
                    categoria: cto.categoria, 
                    numero: cto.numero,
                    titular: cto.titular,
                    pacote: cto.pacote,
                    data: (cto.data).replace('.', '')
                }])
            } else {
                setContrato([null])
            }
            
            switchLoader(0)
        })
    }

    function handleLogin(){
        window.open(`${ENDPOINT}category-auth/google`, '_self')
    }

    function handleLogout() {
        window.open(`${ENDPOINT}category-auth/logout`, '_self')
    }

    function switchLoader(n){
        let loader = document.getElementById('wrapper')
        n === 0 ? loader.style.display = 'none' : loader.style.display = 'flex'
    }

    if (!user) {
        return (
            <div className="profile-page">
                <div className="profile-content">
                    <div className="profile-login-card">
                        <div className="luto-icon" style={{ textAlign: 'center' }}>
                            <img alt="icon luto" src="icon-luto.png" width='200px' height='auto'></img>
                        </div>
                        <div className="profile-login-card-top" style={{ marginTop: '20px' }}>
                            <h1>Página de Fidelidade</h1>
                        </div>
                        <div className="profile-login-card-buttons">
                            <div className="profile-login-buttons" style={{ width: '400px' }} onClick={() => handleLogin()}>
                                <img alt="google logo" src="google-logo.svg" width='45px' height='auto'></img>
                                <p style={{ textAlign: 'center', marginLeft: '5px' }}>Entrar com Google</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="page">
            <div id="wrapper" style={{ display: 'none' }}>
                <div id="loader"></div>
            </div>

            <div className='page-content'>
                <div className="sv-title-div">
                    <div style={{ width: '33%', textAlign: 'left' }}>
                        <img alt="icon luto" src="icon-luto.png" width='40px' height='40px' style={{ marginLeft: '1vw' }}></img>
                    </div>
                    <h1 style={{ width: '34%' }}>Categoria do contrato</h1>
                    <div style={{ width: '33%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <div onClick={() => handleLogout()} className="logout-bnt">Sair</div>
                    </div>
                </div>
                

                <div className="search-contract-div">
                    <div className="form">
                        <p style={{ fontSize: '26px' }}>Número do contrato</p>
                        <input type="text" placeholder="0" className="search-input" style={{ border: '1px solid black', width: '220px', marginTop: '10px'}}
                        onChange={(e) => setSearch(e.target.value)}></input>
                        <button type="submit" className="search-button" style={{ width: '250px', marginTop: '10px' }} onClick={() => getCategory()}>Buscar</button>
                    </div>

                    {contrato[0] !== null ? (
                        contrato.map((cto, i) => (
                            <div className="form" style={{ marginTop: '50px', border: '1px solid black', borderRadius: '5px', width: 'auto'
                            , padding: '20px 10px', fontSize: '20px', textAlign: 'center', whiteSpace: 'nowrap', boxSizing: 'content-box' }} key={i}>
                                <p>Número do Contrato: {cto.numero}</p>
                                <p>Titular: {cto.titular}</p>
                                <p>Pacote: {cto.pacote}</p>
                                <p>Ano do contrato: {cto.data}</p>
                                <div style={{ width: '250px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px',
                                backgroundColor: cto.categoria === 'GOLD' ? '#ffc000' 
                                : cto.categoria === 'PLATINUM' ? '#d8d8d8' 
                                : cto.categoria === 'BLACK' ? 'black'
                                : cto.categoria === 'SAFIRA' ? '#0070c0' : 'none',
                                }}>
                                    <p style={{ fontFamily: 'Roboto Medium', fontSize: '22px',
                                    color: cto.categoria === 'GOLD' ? 'black' 
                                        : cto.categoria === 'PLATINUM' ? 'black' 
                                        : cto.categoria === 'BLACK' ? 'white'
                                        : cto.categoria === 'SAFIRA' ? 'white' : 'none'
                                    }} className="sv-info">{cto.categoria}</p>
                                </div>
                                <br />
                                {cto.categoria === 'GOLD' ? ''
                                : cto.categoria === 'PLATINUM' ? <img src='categorias/ClientePlatinum.jpg' alt='beneficios' />
                                : cto.categoria === 'BLACK' ? <img src='categorias/ClienteBlack.jpg' alt='beneficios' />
                                : cto.categoria === 'SAFIRA' ? <img src='categorias/ClienteSafira.jpg' alt='beneficios' /> 
                                : ''
                                }
                            </div>
                        ))
                    ) : (
                        <div className="form" style={{ marginTop: '50px' }}>
                            <p style={{ fontSize: '24px' }}>Contrato não encontrado</p>
                        </div>
                    )}

                    
                </div>
            </div>
        </div>
    )
}

export default Categoria